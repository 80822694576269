<template>
  <div id="TermsAndConditions">
    <v-container class="d-flex flex-column align-center pt-10" fluid>
      <WsLogoHeader />
      <h1 class="text-capitalize">WeStore subscription plan terms and conditions</h1>
      <section id="freeTrial">
        <v-container>
          <v-row class="d-flex flex-column align-center justify-center">
            <v-col md="8" sm="12" class="text-center">
              <h3 class="text--pink">Free Trial</h3>
            </v-col>
            <v-col md="8" sm="12">
              <ul>
                <li>Except for the payment and termination clauses, all <strong>WeStore</strong>
                  <TermsOrServiceLink/>
                  apply during the trial period. By using <strong>WeStore</strong> Services, you agree to be
                  bound by the <strong>WeStore</strong>
                  <TermsOrServiceLink/>
                  in addition to these terms.
                </li>
                <li>The free trial is valid for 7 days.</li>
                <li>You will choose a subscription plan prior to the trial period starting but will not be charged during
                  the free trial period.
                </li>
                <li>If you do not cancel the subscription during the first 7 days, you will be invoiced for the chosen
                  subscription plan.
                </li>
                <li>You may cancel the subscription plan during the trial period by emailing <a
                    href="mailto:connect@westore.ai" role="link">connect@westore.ai</a></li>
                <li>The free trial includes up to AED 300 in value of the following subscription plan services:
                  <ul>
                    <li>Storage</li>
                    <li>Labeling</li>
                    <li>Picking and Packing</li>
                    <li>Inventory Management</li>
                  </ul>
                </li>
                <li>Any services provided above AED 300 in value during the trial period will be charged to you.</li>
                <li>During the free trial, you will also be charged separately for the following value-added services:
                  <ul>
                    <li>Delivery</li>
                    <li>COD</li>
                    <li>Returns</li>
                  </ul>
                  <a href="#pricingList">Click here</a> to see the list of charges for both the subscription and
                  value-added
                  services.
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="subscriptionPlan" class="mt-5">
        <v-container>
          <v-row class="d-flex flex-column align-center justify-center">
            <v-col md="8" sm="12" class="text-center">
              <h3 class="text--pink">Subscription Plan</h3>
            </v-col>
            <v-col md="8" sm="12">
              <ul>
                <li>Except for payment and termination clauses, all <strong>WeStore</strong><TermsOrServiceLink/>
                  apply to the chosen subscription plan. By using <strong>WeStore</strong> Services, you agree
                  to be bound by the <strong>WeStore</strong>
                  <TermsOrServiceLink/>
                  in addition to these terms.
                </li>
                <li>The subscription plan charges and service limits are subject to change at any time. However, any new
                  charges or service limits will not apply to existing customers without 30-day written notice from
                  <strong>WeStore</strong>.
                </li>
                <li>You will be invoiced for your chosen subscription plan and any value-added services at the end of each
                  30-day cycle.
                </li>
                <li>You may renew, upgrade, or cancel your plan at the end of every 30-day cycle.</li>
                <li>You may cancel the subscription plan by emailing <a href="mailto:connect@westore.ai" role="link">connect@westore.ai</a>
                </li>
                <li>Your subscription plan includes the following services, up to the limits of the chosen
                  subscription plan:
                  <ul>
                    <li>Storage</li>
                    <li>Labeling</li>
                    <li>Picking and Packing</li>
                    <li>Inventory Management</li>
                  </ul>
                </li>
                <li>Any services provided above the limits of the subscription plan will be charged
                  separately.
                </li>
                <li>You will also be charged separately for the following value-added services:
                  <ul>
                    <li>Delivery</li>
                    <li>COD</li>
                    <li>Returns</li>
                  </ul>
                  <a href="#pricingList">Click here</a> to see the list of charges for both the subscription and
                  value-added
                  services.
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <section id="pricingList" class="col-sm-12">
        <v-container>
          <v-row class="d-flex flex-column align-center">
            <v-col md="8" sm="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th colspan="2" class="text--pink text-body-1">Pricing List</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, i) in pricingList" :key="i">
                    <td v-text="item.name"></td>
                    <td v-html="item.pricing"></td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <WsWhatsappPopup category="plan" />
    </v-container>
    <WsFooter />
  </div>
</template>
<script>
import mixins from "vue-typed-mixins";
import MyMixin from "@/plugins/myMixin";
import {metadata} from "@/plugins/metadata";

import WsLogoHeader from "@/components/ws-logo-header";
import WsFooter from "@/components/ws-footer";
import TermsOrServiceLink from "@/components/Links/TermsOfServiceLink";
import WsWhatsappPopup from "@/components/ws-whatsapp-popup";

export default mixins(MyMixin).extend({
  name: 'TermsAndConditions',
  components: {WsLogoHeader, WsFooter, TermsOrServiceLink, WsWhatsappPopup},
  metaInfo: {
    title: metadata["Terms and Conditions"].title,
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {charset: "utf-8"},
      {name: "robots", content: "follow, index"},
      {
        name: "description",
        content: metadata["Terms and Conditions"].description,
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1, user-scalable=no",
      },
    ],
  },
  data() {
    return {
      pricingList: [
        {name: "Dry Storage", pricing: "2 AED/per CBM/per day"},
        {name: "Pick & Pack", pricing: "5 AED/order (up to 3 items)<br>1 AED for each additional item "},
        {name: "Labeling", pricing: "1 AED/per item"},
        {name: "Last-mile delivery across UAE (next day)", pricing: "15 AED/order"},
        {name: "Last-mile delivery across UAE (same day)", pricing: "30 AED/order"},
        {name: "Cash on Delivery", pricing: "5 AED/order"},
        {name: "Returns across UAE", pricing: "15 AED/order"},
        {name: "Handling in", pricing: "10 AED/order"},
        {name: "Handling out", pricing: "10 AED/order"},
        {name: "Kitting", pricing: "To be determined accordingly"},
        {name: "Customized packaging", pricing: "To be determined accordingly"},
        {name: "Transporting inventory to the facility", pricing: "To be determined accordingly"},
      ]
    }
  },
  mounted() {
    this.trackMyPageView();
  }
});
</script>